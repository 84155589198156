// copied from https://github.com/rjsf-team/react-jsonschema-form/blob/b78d9ef280eddf5bda0f97e5a3445c6a1fd35c99/packages/core/src/components/widgets/SelectWidget.js
// workaround https://github.com/rjsf-team/react-jsonschema-form/issues/1041
import React from 'react'

//import { asNumber, guessType } from "../../utils";

const guessType = function guessType(value) {
  if (Array.isArray(value)) {
    return 'array'
  } else if (typeof value === 'string') {
    return 'string'
  } else if (value == null) {
    return 'null'
  } else if (typeof value === 'boolean') {
    return 'boolean'
  } else if (!isNaN(value)) {
    return 'number'
  } else if (typeof value === 'object') {
    return 'object'
  }
  // Default to string if we can't figure it out
  return 'string'
}

function asNumber(value) {
  if (value === '') {
    return undefined
  }
  if (value === null) {
    return null
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value
  }
  const n = Number(value)
  const valid = typeof n === 'number' && !Number.isNaN(n)

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value
  }

  return valid ? n : value
}

const nums = new Set(['number', 'integer'])

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, uiSchema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema
  if (value === '') {
    // workaround for https://github.com/rjsf-team/react-jsonschema-form/issues/1041
    if (uiSchema && typeof uiSchema['ui:emptyValue'] !== 'undefined') {
      return uiSchema['ui:emptyValue']
    }
    return undefined
  } else if (type === 'array' && items && nums.has(items.type)) {
    return value.map(asNumber)
  } else if (type === 'boolean') {
    return value === 'true'
  } else if (type === 'number') {
    return asNumber(value)
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every((x) => guessType(x) === 'number')) {
      return asNumber(value)
    } else if (schema.enum.every((x) => guessType(x) === 'boolean')) {
      return value === 'true'
    }
  }

  return value
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter((o) => o.selected)
      .map((o) => o.value)
  } else {
    return event.target.value
  }
}

export default function SelectWidget(props) {
  const {
    uiSchema,
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus = false,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props
  const { enumOptions, enumDisabled } = options
  const emptyValue = multiple ? [] : ''
  return (
    <select
      id={id}
      multiple={multiple}
      className="form-control"
      value={typeof value === 'undefined' ? emptyValue : value}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      onBlur={
        onBlur &&
        ((event) => {
          const newValue = getValue(event, multiple)
          onBlur(id, processValue(schema, uiSchema, newValue))
        })
      }
      onFocus={
        onFocus &&
        ((event) => {
          const newValue = getValue(event, multiple)
          onFocus(id, processValue(schema, uiSchema, newValue))
        })
      }
      onChange={(event) => {
        const newValue = getValue(event, multiple)
        onChange(processValue(schema, uiSchema, newValue))
      }}
    >
      {!multiple && schema.default === undefined && (
        <option value="">{placeholder}</option>
      )}
      {enumOptions.map(({ value, label }, i) => {
        const disabled = enumDisabled && enumDisabled.indexOf(value) !== -1
        return (
          <option key={i} value={value} disabled={disabled}>
            {label}
          </option>
        )
      })}
    </select>
  )
}
